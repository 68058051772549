import { styled } from '@linaria/react'
import { textOverflowStyles } from 'common/styles/textOverflow'
import { VoidHandler } from 'common/types'
import React, { FC, ReactNode } from 'react'
import { buttonResetCss } from '../styles/buttonResetCss'
import { Typography } from '../Typography/Typography'
import { CloseSvg } from '../svgr/CloseSvg'

export const BottomSheetTitle: FC<{
  title: ReactNode
  onCloseClick: VoidHandler
}> = ({ title, onCloseClick }) => {
  return (
    <Title>
      <TitleLeft>
        <CloseSvg />
      </TitleLeft>
      <TitleText fontSize={24} fontWeight={700}>
        {title}
      </TitleText>
      <TitleRight>
        <CloseButton
          onClick={onCloseClick}
          data-name="bottom-sheet-close-action"
        >
          <CloseSvg />
        </CloseButton>
      </TitleRight>
    </Title>
  )
}

const Title = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
`
const TitleText = styled(Typography)`
  ${textOverflowStyles};
`
const TitleLeft = styled.div`
  visibility: hidden;
`
const TitleRight = styled.div`
  position: relative;
  width: 24px;
  height: 24px;
  font-size: 0;
`
const CloseButton = styled.button`
  ${buttonResetCss};
  position: relative;
  cursor: pointer;
  position: absolute;
  padding: 20px;
  transform: translate(-50%, -50%);
  top: 50%;
  left: 50%;
  display: none;

  @media (hover: hover) and (pointer: fine) {
    display: block;
  }
`
