import { noop } from 'common/constants'
import { VoidHandler } from 'common/types'
import { usePrevious } from 'hooks/usePrevious'
import { useShallowEqualSelector } from 'hooks/useShallowEqualSelector'
import React, {
  ComponentPropsWithoutRef,
  MutableRefObject,
  ReactNode,
  forwardRef,
  useCallback,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
} from 'react'
import { createPortal } from 'react-dom'
import { BOTTOM_SHEET_MOBILE_PORTAL_ID } from './BottomSheet.constants'
import { BottomSheetInner } from './BottomSheetInner'
import { iOsOsType } from 'common-constants/userAgent'

export const useBottomSheet = () => {
  const [isOpen, setIsOpen] = useState(false)
  const ref = useRef<BottomSheetRefHandlers>({
    open: noop,
    close: noop,
  })

  const open = useCallback((data?: unknown) => {
    ref.current.open(data)
    setIsOpen(true)
  }, [])
  const close = useCallback((callBack?: VoidHandler) => {
    ref.current.close(callBack)
    setIsOpen(false)
  }, [])

  return {
    ref,
    open,
    close,
    isOpen,
  }
}

export type BottomSheetRefHandlers = {
  open: VoidHandler
  close: VoidHandler
}

type BottomSheetInnerProps = ComponentPropsWithoutRef<typeof BottomSheetInner>
type InnerProps = Pick<BottomSheetInnerProps, 'children' | 'dataName'>

type Props = {
  ref: MutableRefObject<BottomSheetRefHandlers>
  title?: ReactNode
  subtitle?: ReactNode
  /** Если нужно расположить контент по-центру на десктопе */
  centerContentVerticallyOnDesktop?: boolean
  /** Вызывается перед анимацией закрытия */
  onClose?: VoidHandler
  /** Вызывается после анимации закрытия */
  onClosed?: VoidHandler
  /**
   * Чтобы список не сжимался по высоте.
   * Например при фильтрации списка высота контейнера будет уменьшаться.
   */
  noHeightShrink?: boolean
  desktopPortal?: HTMLElement | null
} & InnerProps

export const BottomSheet = forwardRef<BottomSheetRefHandlers, Props>(
  (props, ref) => {
    const { onClose, onClosed, desktopPortal } = props
    const {
      touch,
      mobile,
      isIOS,
      animationsDisabled,
    } = useShallowEqualSelector(
      ({
        systemReducer: { touch, osType, animationsDisabled },
        systemView: { mobile },
      }) => ({
        isIOS: osType === iOsOsType,
        touch,
        mobile,
        animationsDisabled,
      })
    )
    const [open, setOpen] = useState<boolean | null>(null)
    const [mounted, setMounted] = useState<boolean>(false)
    const callBackRef = useRef<VoidHandler | undefined>()
    const [data, setData] = useState<unknown>()

    useImperativeHandle(ref, () => ({
      open: (data: unknown) => {
        setMounted(true)
        setOpen(true)
        setData(data)
      },
      close: (callBack?: VoidHandler) => {
        setOpen(false)
        callBackRef.current = callBack
      },
    }))

    const prevOpen = usePrevious(open)
    useEffect(() => {
      if (prevOpen && !open) {
        onClose?.()
      }
    }, [onClose, open, prevOpen])

    const prevMounted = usePrevious(mounted)
    useEffect(() => {
      if (prevMounted && !mounted) {
        onClosed?.()
        if (callBackRef.current) {
          callBackRef.current()
          callBackRef.current = undefined
        }
      }
    }, [prevMounted, mounted, onClosed])

    const onChildrenUnmounted = useCallback(() => {
      setMounted(false)
    }, [])

    if (!mounted) {
      return null
    }

    const element = (
      <BottomSheetInner
        key={Number(mobile)}
        {...props}
        mobile={mobile}
        isIOS={isIOS}
        data={data}
        open={open}
        setOpen={setOpen}
        onUnmounted={onChildrenUnmounted}
        animationsDisabled={animationsDisabled}
      />
    )

    if (!touch) {
      return desktopPortal ? createPortal(element, desktopPortal) : element
    }

    const mobilePortal = document.getElementById(BOTTOM_SHEET_MOBILE_PORTAL_ID)

    if (!mobilePortal) {
      console.error('Портал для BottomSheet не найден.')
      return null
    }

    return createPortal(element, mobilePortal)
  }
)
