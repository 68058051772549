export const isScrolledToBottom = (node?: HTMLElement | null) => {
  if (!node) {
    return
  }

  const scrolled =
    Math.round(node.scrollTop + node.clientHeight) ===
    Math.round(node.scrollHeight)

  return scrolled
}
